<style scoped lang="scss">
.curriculum {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.look-btn {
  padding: 0 15px;
  height: 30px;
  line-height: 30px;
}

.curriculum-search {
  flex: none;
}

.curriculum-table {
  flex: 1;
  overflow: hidden;

  .table_center {
    height: calc(100% - 60px);
    overflow-y: scroll;

    .using,
    .interdict {
      position: relative;
    }

    .using::after {
      content: "";
      width: 6px;
      height: 6px;
      background-color: #29ca57;
      border-radius: 50%;
      position: absolute;
      right: -6px;
      top: -5px;
    }

    .interdict::after {
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #f7415f;
      position: absolute;
      right: -6px;
      top: -5px;
    }
  }
}

// 分页
.paging {
  padding: 10px 0;
  text-align: right;
}

// 新增课程
.new-courses {
  width: 100%;
  height: 68px;
  line-height: 68px;
  background-color: #f6f7fc;
  font-size: 16px;
  font-weight: 600;
  color: #1b1b1b;
  position: relative;
  text-indent: 36px;
}

.newcourses {
  .new-courses-content {
    width: 100%;
    background: #ffffff;
    padding: 50px 57px 43px;
    box-sizing: border-box;
  }
}

.new-courses::after {
  content: "";
  width: 5px;
  height: 18px;
  background-color: #6340c8;
  border-radius: 2.5px;
  position: absolute;
  top: 26px;
  left: 25px;
}

.curriculum-search {
  text-align: right;
  margin-bottom: 18px;
}

.el-select-dropdown.is-multiple .el-select-dropdown__item {
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
  margin: 0 !important;
  background: #fff;
}

.el-select-dropdown__item {
  height: auto;
  overflow: hidden;
  overflow-y: hidden;
  padding: 0;
}

.el-tree .el-tree-node__content {
  height: auto;
  padding: 0 20px;
}

.el-tree-node__label {
  font-weight: normal;
}

::v-deep {
  .newcourses .el-dialog {
    border-radius: 10px;
    overflow: hidden;
  }

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 0;
  }

  .curriculum-search .el-input--medium .el-input__inner {
    min-width: 250px;
    // height: 50px;
    // line-height: 50px;
    background: #f1f2f8;
    border-radius: 10px;
    border: none;
  }

  // .el-form-item--medium .el-form-item__content {
  //     line-height: 50px;
  // }

  .curriculum-search .el-form-item--medium .el-form-item__label {
    line-height: 50px;
  }

  .curriculum-search .el-form--inline .el-form-item {
    margin-right: 19px;
  }

  // kaiguan
  .el-switch__core {
    width: 60px !important;
    height: 30px;
    background-color: #2ac293;
    border-radius: 20px;
  }

  .el-switch__core:after {
    width: 28px;
    height: 28px;
    top: 0;
  }

  .el-switch.is-checked .el-switch__core::after {
    margin-left: -28px;
  }
}

// 新增课程
::v-deep {
  .new-courses-content .el-input--medium .el-input__inner {
    height: 46px;
    line-height: 46px;
    width: 393px;
    background: #f6f7fa;
    border: none;
    border-radius: 10px;
  }

  .new-courses-content .el-form-item--medium .el-form-item__label {
    line-height: 46px;
  }

  .el-dialog .el-dialog__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.custom-form-item {
  margin-top: 49px;
  padding-top: 26px;
  text-align: right;
  border-top: 1px solid #f8f8f8;
}
</style>
<template>
  <section class="curriculum">
    <div class="curriculum-search">
      <el-button type="primary" @click="handeldetailed($event)" class="">
        新增角色
        <i class="el-icon-plus"></i>
      </el-button>
    </div>

    <div class="curriculum-table">
      <template>
        <div class="table_center">
          <el-table :data="tableData" style="width: 100%" :header-cell-style="{
            background: '#E8EAF3',
            color: '#1a1a1b',
            'text-align': 'center',
            fontWeight: 500,
          }">
            <!--  role: "正式",
                                status: -->
            <el-table-column prop="arole_name" label="角色"> </el-table-column>
            <el-table-column label="状态">
              <template slot-scope="scope">
                <span class="using" v-if="scope.row.arole_status == 1">启用</span>

                <span class="interdict" v-if="scope.row.arole_status != 1">禁用</span>

              </template>
            </el-table-column>
            <el-table-column label="操作" width="400">
              <template slot-scope="scope">
                <el-button type="primary" class="look-btn green-btn"
                  @click="handeldetailed($event, scope.row.arole_id)">编辑</el-button>

                <el-button type="primary" class="look-btn lightpurple-btn" @click="
                 updateMenu(scope.row)">分配菜单</el-button>
                <el-button type="primary" class="look-btn red-btn" @click="handeldelBtn(scope.row.arole_id)">删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="paging">
          <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10"
            next-text="下一页" layout=" pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </template>
    </div>

    <!-- 新增编辑角色 -->
    <el-dialog :show-close="false" width="659px" class="newcourses" :visible.sync="addroleVisible">
      <div class="new-courses">
        {{ detailedflog == false ? "新增角色" : "编辑角色" }}
      </div>

      <div class="new-courses-content">
        <el-form label-width="80px" :model="newcoursesData" ref="newcoursesData" :rules="editrules">
          <el-form-item label="角色名称" prop="arole_name">
            <el-input v-model="newcoursesData.arole_name" :maxlength="20" show-word-limit></el-input>
          </el-form-item>

          <el-form-item label="状态" prop="arole_statuss">
            <el-switch v-model="newcoursesData.arole_status" active-color="#2AC293" inactive-color="#ff4949"
              :active-value="1" :inactive-value="2">
            </el-switch>
          </el-form-item>

          <el-form-item class="custom-form-item">
            <el-button type="primary" class="lightgrey-btn" @click="addroleVisible = false">取消</el-button>
            <el-button class="lightpurple-btn" type="primary" @click="handeledit('newcoursesData')">确定
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <!-- 删除弹框 -->

    <dialog-bar v-model="deletebar" type="danger" title="删除" content="确认删除当前角色？" v-on:cancel="clickCancel()"
      @danger="clickDanger()" @confirm="clickConfirm()" dangerText="确定"></dialog-bar>

    <!-- 权限配置弹窗 -->
    <el-dialog title="权限配置" :visible.sync="distributionVisible" width="440px">
    
      <el-tree :data="nodeTreeData" :props="nodeProps" :default-checked-keys="nodeIds" node-key="anode_id" show-checkbox
        default-expand-all check-strictly ref="nodeTree" @check="checkNode" @check-change="checkNodeChange">
      </el-tree>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleNodeCancle()">取 消</el-button>
        <el-button type="primary" @click="handleNodeSubmit()">
          保 存
        </el-button>
      </div>
    </el-dialog>
    
    <!-- 菜单分配 -->
    <el-dialog  width="498px" :visible.sync="authMenuDialog" class="newcourses" :show-close="false">
      <div class="new-courses">
        分配菜单
      </div>
      <div class="dialog-container">
        <div class="authority-tree">
          <el-tree ref="Menutree" :data="authMenuData" :default-expanded-keys="checked_list"
            :default-checked-keys="checked_list" :props="menuDefaultProps" show-checkbox node-key="amenu_id" />
        </div>
      </div>
      <div slot="footer">
        <el-button type="custom_info" size="small" @click="authMenuDialog = false">
          取 消
        </el-button>
        <el-button type="custom_primary" size="small" @click="menuConfirm">
          确 定
        </el-button>
      </div>
    </el-dialog>

    <!-- 角色分配弹窗 -->
    <el-dialog :show-close="false" width="659px" class="newcourses">
      <div class="new-courses">权限分配</div>

      <div class="new-courses-content">
        <el-form label-width="80px" :model="newcoursesData">
          <el-form-item label="角色名称">
            <div class="box">
              <!--  <el-select
                v-model="selectArr"
                value-key="value"
                placeholder="请选择"
                multiple
                @change="changeSelect"
              >
                <el-option
                  v-for="item in selectArr"
                  :key="item.value"
                  :label="item.label"
                  :value="item"
                  style="display: none"
                >
                </el-option>
                <el-option :value="value">
                  <el-tree
                    ref="tree"
                    node-key="id"
                    show-checkbox
                    :data="data"
                    :default-expanded-keys="defaultOpen"
                    :props="defaultProps"
                    :default-checked-keys="defaultChecked"
                    @check-change="getCurrentSelectArray"
                    @current-change="getCurrentSelectArray"
                  />
                </el-option>
              </el-select> -->
              <el-tree :data="nodeTreeData" :props="nodeProps" :default-checked-keys="nodeIds" node-key="anode_id"
                show-checkbox default-expand-all check-strictly ref="nodeTree" @check="checkNode"
                @check-change="checkNodeChange">
              </el-tree>
            </div>
          </el-form-item>

          <el-form-item class="custom-form-item">
            <el-button type="primary" class="lightgrey-btn" @click="distributionVisible = false">取消
            </el-button>
            <el-button class="lightpurple-btn" type="primary">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import {
  getRoleList,
  getRoleInfo,
  deleteRole,
  editRole,
  addRole,
  getTreeNode,
  getRoleNodeId,
  saveRoleNode,
  saverolemenu,
  getrolemenuid,
  gettreemenu
} from "@api/role";
export default {
  name: "",
  components: {
    dialogBar: () => import("@/components/configbox.vue"), //引入弹框组件
  },
  data() {
    return {
      currentPage: 1,
      size: 10,
      total: 120,
      searchform: {
        shool: "",
        grades: "",
        classTime: "",
      },

      // 表格数据
      tableData: [],

      // 新增课程
      addroleVisible: false,
      checked_list: [], //菜单列表
      menuDefaultProps: {
                children: 'children',
                label: 'amenu_name',
            },//菜单下拉配置

      // 判断新增or编辑
      detailedflog: false,

      // 分配权限
      distributionVisible: false,
      authMenuDialog: false,//菜单分配弹窗
      nodeIds: [], //勾选的权限节点id
      nodeTreeData: [], //树状数据列表
      nodeProps: {
        label: "anode_nickname", //指定节点标签的属性值
        children: "children", //指定子树的属性值
      }, //树状数据配置项

      newcoursesData: {
        arole_name: "",
        arole_status: 1,
      },

      // 删除
      deletebar: false,

      // 分配选线树
      data: [],
      authMenuData: [],//菜单分配数据
      /**
       * id: 数据id
       * pid: 下级数据id
       * label: 数据label
       * checked: 是否选中
       */
      deleteId: "", //删除id
      initData: [
        {
          id: 1,
          pid: 0,
          label: "1-0",
          checked: false,
        },
        {
          id: 2,
          pid: 1,
          label: "1-1",
          checked: true,
        },
        {
          id: 3,
          pid: 1,
          label: "1-2",
          checked: false,
        },
        {
          id: 4,
          pid: 3,
          label: "1-1",
          checked: true,
        },
        {
          id: 5,
          pid: 3,
          label: "1-2-2",
          checked: false,
        },
      ],
      editrules: {
        arole_name: [
          {
            required: true,
            message: "请输入角色名字",
            trigger: "blur",
          },
        ],
        arole_status: [
          {
            required: true,
            message: "请选择角色状态",
            trigger: "blur",
          },
        ],
      },
      defaultProps: {
        children: "children",
        label: "label",
      },
      value: "", // options
      defaultChecked: [], // 默认选中的值
      defaultOpen: [], // 默认展开的选项数组
      selectArr: [], // 选择栏绑定值
    };
  },

  mounted() {
    this.data = this.JsonToTree(this.initData, 0);
    this.getData();
    this.getMenuThree();
  },
  methods: {
    async getData() {
      const pages = {
        pageindex: this.currentPage,
        pagesize: this.size,
      };
      var res;
      const { data: resdata } = await getRoleList(this.searchForm, pages);
      res = resdata;
      this.tableData = res.data; //数据
      this.total = res.allcount; //总数
      this.currentPage = res.pageindex; //当前页

      this.$forceUpdate();
    },
    onSubmit() { },
    handleClick() {
      this.$router.push({
        path: "/course/info",
      });
      // this.$router.push({path: '/login?url=' + this.$route.path});
    },

    // 修改当前
    handleCurrentChange() { },
    //提交信息
    async handeledit() {
      this.$refs["newcoursesData"].validate(async (valid) => {
        if (valid) {
          var res;
          let submitFormData = JSON.parse(JSON.stringify(this.newcoursesData));

          /** 请求編輯接口*/
          if (submitFormData.arole_id) {
            res = await editRole(submitFormData);
          } else {
            /** 请求新增接口*/
            res = await addRole(submitFormData);
          }
          if (res.status != 200) {
            this.$message({
              message: res.msg,
              type: "error",
            });
            return;
          };

          this.$message({
            message: res.msg,
            type: "success",
          });
          this.getData(); // 请求成功后，调用方法，更新页面数据
          this.addroleVisible = false;

          this.$refs["newcoursesData"].clearValidate(); // 处理成功，调用方法关闭弹窗
        }
      });
    },
    /** 菜单树状列表 */
    async getMenuThree() {
      const res = await gettreemenu();
      if (res.status == 200) {
        this.authMenuData = res.data;
      }
    },
    /**编辑菜单权限 */
    async updateMenu(row) {     
      const res = await getrolemenuid({ issuper: row.arole_issuper, roleid: row.arole_id })      
      if (res.status == 200) {
        this.authMenuDialog = true;
        this.$nextTick(() => {
          this.$refs.Menutree.setCheckedKeys([]);
          this.checked_list = res.data;
          this.roleid = row.arole_id;
        });
      }

    },
    /**菜单权限确认 */
    async menuConfirm() {
      let params = { roleid: this.roleid, menuarr: [] }
      let nodearr = this.$refs.Menutree.getCheckedKeys();
    
     
      nodearr.map(item => params.menuarr.push({ amenu_id: item }));
      const res = await saverolemenu(params);
      if (res.status == 200) {
        this.$message.success("配置成功");
        this.authMenuDialog = false;
      }
    },

    // 新增编辑详情
    async handeldetailed(e, id) {
      if (id) {
        this.clearAddForm();
        // 获取角色信息
        const { data: resdata } = await getRoleInfo(id);
        resdata.status = String(resdata.status);
        this.newcoursesData = resdata;

        this.detailedflog = true;
      } else {
        this.clearAddForm();
        this.detailedflog = false;
      }

      this.addroleVisible = true;
    },
    clearAddForm() {
      this.newcoursesData.arole_name = "";
      this.newcoursesData.arole_id = "";
    },

    // 删除按钮
    handeldelBtn(id) {
      this.deleteId = id;
      this.deletebar = true;
    },

    clickCancel() {
      this.clearAddForm();
    },
    //删除方法
    async clickDanger() {
      let id = this.deleteId;
      if (id == "") {
        this.$message({
          type: "error",
          message: "id错误",
        });
      }
      /*请求接口删除数据*/
      const res = await deleteRole(id);

      if (res.status == 200) {
        this.$message({
          type: "success",
          message: res.msg,
        });
        this.getData();
      } else {
        this.$message({
          type: "error",
          message: res.msg,
        });
        this.getData();
      }
      this.deleteId = "";
    },
    clickConfirm() {
   
    },

    getCurrentSelectArray() {
      // 若节点可被选择，则返回目前被选中的节点所组成的数组 接收两个boolean 类型的参数，1. 是否只是叶子节点，默认值为 false 2. 是否包含半选节点，默认值为 false
      var arr = this.$refs.tree.getCheckedNodes(true, false);
      this.selectArr = [];
      // 赋值
      arr &&
        arr.length &&
        arr.forEach((item) => {
          this.selectArr.push({
            label: item.label,
            value: item.id,
          });
        });
      // 此处同下 有bug  不采用
      /* this.$nextTick(() => {
                // 获取当前选中元素 添加激活类
                var dom = document.querySelectorAll("div[role='group'] div[role='treeitem']")
                dom.forEach(item => {
                  if (item.children.length <= 1) {
                    item.classList.add('current-checked')
                  }
                })
              }) */
    },
    changeSelect(val) {
      // 设置当前节点的选中状态 val 为数组， 第二个参数为 是否选中
      var treeCheckedIdList = [];
      this.initData.forEach((item) => {
        val.forEach((checked) => {
          if (checked.value === item.id) {
            treeCheckedIdList.push(item.id);
          }
        });
      });
      this.$refs.tree.setCheckedKeys(treeCheckedIdList, true);
      //
    },
    // json 转 树形
    JsonToTree(data, pid) {
      var itemArr = [];
      for (var i = 0; i < data.length; i++) {
        var node = data[i];
        /* eslint-disable */
        if (node.pid == pid) {
          if (node.checked) {
            // 初始化数据 - 数据绑定
            this.defaultChecked.push(node.id);
            this.defaultOpen.push(node.id);
            this.selectArr.push({
              label: node.label,
              value: node.id
            });
          }
          var newNode = {
            id: node.id,
            pid: node.pid,
            label: node.label,
            checked: node.checked,
            children: this.JsonToTree(data, node.id),
          };
          itemArr.push(newNode);
        }
      }
      return itemArr;
    },

    //加载权限分配界面
    async handleInitRoleNode(roleid, issuper) {
      this.id = roleid;
      let { data: res } = await getTreeNode();
      let { data: resnode } = await getRoleNodeId(roleid, issuper);

      this.nodeTreeData = res;
      this.nodeIds = resnode;
      this.distributionVisible = true;

    },
    //关闭权限配置弹窗
    handleNodeCancle() {
      this.nodeIds = [];
      this.distributionVisible = false;
    },

    //保存权限节点数据
    async handleNodeSubmit() {
      let checkNodes = this.$refs.nodeTree.getCheckedNodes();
      let halfCheckNodes = this.$refs.nodeTree.getHalfCheckedNodes();
      checkNodes.unshift.apply(checkNodes, halfCheckNodes);
      let res = await saveRoleNode(this.id, checkNodes);
      if (res.status != 200) return;
      this.$message({ message: res.msg, type: "success" });
      this.handleNodeCancle();
    },
    //处理菜单父节点选中时，全选子节点，父节点取消时，全部取消子节点
    checkMenu(a, b) {
      if (b.checkedKeys.indexOf(a.amenu_id) == -1) {
        if (a.children) {
          //循环子集将他们的选中取消
          a.children.map(item => {
            this.$refs.menuTree.setChecked(item.amenu_id, false);
          })
        }
      } else {
        if (a.children) {
          //循环子集将他们的选中取消
          a.children.map(item => {
            this.$refs.menuTree.setChecked(item.amenu_id, true);
          })
        }
      }
    },

    //父级点击选中或者取消时，子集自动全选或者取消
    checkMenuChange(a, b, c) {
      //如果为取消
      if (b === false) {
        //如果当前节点有子集
        if (a.children) {
          //循环子集将他们的选中取消
          a.children.map(item => {
            this.$refs.menuTree.setChecked(item.amenu_id, false);
          })
        }
      } else {
        //否则(为选中状态)
        //判断父节点id是否为空
        if (a.amenu_parent !== 0) {
          //如果不为空则将其选中
          this.$refs.menuTree.setChecked(a.amenu_parent, true);
        }
      }
    },

    //处理权限父节点选中时，全选子节点，父节点取消时，全部取消子节点
    checkNode(a, b) {
      if (b.checkedKeys.indexOf(a.anode_id) == -1) {
        if (a.children) {
          //循环子集将他们的选中取消
          a.children.map(item => {
            this.$refs.nodeTree.setChecked(item.anode_id, false);
          })
        }
      } else {
        if (a.children) {
          //循环子集将他们的选中取消
          a.children.map(item => {
            this.$refs.nodeTree.setChecked(item.anode_id, true);
          })
        }
      }
    },

    //父级点击选中或者取消时，子集自动全选或者取消
    checkNodeChange(a, b, c) {
      //如果为取消
      if (b === false) {
        //如果当前节点有子集
        if (a.children) {
          //循环子集将他们的选中取消
          a.children.map(item => {
            this.$refs.nodeTree.setChecked(item.anode_id, false);
          })
        }
      } else {
        //否则(为选中状态)
        //判断父节点id是否为空
        if (a.anode_parent !== 0) {
          //如果不为空则将其选中
          this.$refs.nodeTree.setChecked(a.anode_parent, true);
        }
      }
    },

  },
};
</script>
