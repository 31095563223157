import API from "@utils/request";
import { PAGE_SIZE } from "@config";

const USER = "/education/role/";
const MENU = "/education/menu/"; //角色管理->菜单


/**
 * 获取角色列表（分页）
 * @param {object} params
 * @param {object} pages
 */
export async function getRoleList(
  params,
  pages = {
    pageindex: 1,
    pagesize: PAGE_SIZE,
  }
) {
  try {
    return await API.get(USER + "list", {
      params: {
        ...params,
        ...pages,
      },
      headers: { isLoading: true },
    });
  } catch (error) {
    return error;
  }
}




// 用户详情
export async function getRoleInfo(id) {
  try {
    return await API.post(USER + "info", {
		id:id,
		
	});
  } catch (error) {
    return error;
  } 
}

// 新增用户角色
export async function addRole(params) {
  try {
    return await API.post(USER + "add", params);
  } catch (error) {
    return error;
  } 
}


// 编辑用户角色
export async function editRole(params) {
  try {
    return await API.post(USER + "edit", params);
  } catch (error) {
    return error;
  } 
}
// 编辑用户角色
export async function deleteRole(id) {
  try {
    return await API.post(USER + "delete", {
		id:id
	});
  } catch (error) {
    return error;
  } 
}

// 获取节点数据
// export async function getTreeNode(id) {
//   try {
//     return await API.post(USER + "gettreenode", {
// 		headers:{isLoading:true}	
		
// 	});
//   } catch (error) {
//     return error;
//   } 
// }
// 获取用户角色权限ID
export async function getRoleNodeId(id,issuper) {
  try {
    return await API.post(USER + "getrolenodeid", {
		roleid:id,
		issuper:issuper,
		headers:{isLoading:true}
		
	});
  } catch (error) {
    return error;
  } 
}


/**
 * 获取树形权限节点列表
*/
export async function getTreeNode() {
  try {
    return await API.post(USER + "gettreenode", {
      headers: { isLoading: true },
    });
  } catch (error) {
    return error;
  }
}

/**
 * 保存角色清单
*/
export async function saveRoleNode(roleid, nodearr) {
  try {
    return await API.post(USER + "saverolenode",
      { roleid: roleid, nodearr: nodearr },
      {
        headers: { isLoading: true },
      });
  } catch (error) {
    return error;
  }
}

/**
 * 刷新角色权限清单
*/
export async function refreshRoleNode() {
  try {
    return await API.post(USER + "refreshrolenode",
      {},
      {
        headers: { isLoading: true },
      });
  } catch (error) {
    return error;
  }
}

/**
 * 获取菜单树状
 * @param {object} params
 * @returns
 */
export async function gettreemenu(params) {
  try {
    return await API.post(MENU + "gettreemenu", params);
  } catch (error) {
    return error;
  }
}
/**
 * 获取菜单已授权id
 * @param {object} params
 * @returns
 */
export async function getrolemenuid(params) {
  try {
    return await API.post(MENU + "getrolemenuid", params);
  } catch (error) {
    return error;
  }
}
/**
 * 保存菜单配置
 * @param {object} params
 * @returns
 */
export async function saverolemenu(params) {
  try {
    return await API.post(MENU + "saverolemenu", params);
  } catch (error) {
    return error;
  }
}



// 用户详情
export async function getInfo(params) {
  // try {
  //   return await API.post(USER + "vue-element-admin/user/info", params);
  // } catch (error) {
  //   return error;
  // }
  return {
    code: '000000',
    data:  {
      id: 1, // id
      name: 'admin',  // 前端路由名称
      avatar: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif?imageView2/1/w/80/h/80',  
      roles:"管理员"
    },
  }
}

